import { Env } from '@/lib/env';

import { signOut } from './auth-methods';

export const INACTIVITY_TIME = Env.application.logoutTime;

// Initial setting of user activity without resetting it in subsequent detections
export function setInitialActivity() {
  if (localStorage.getItem('lastUserActivity') === null) {
    activityDetected();
  }
}

export function activityDetected() {
  try {
    const now = Date.now();
    localStorage.setItem('lastUserActivity', now.toString());
  } catch (err) {
    console.error('Error setting lastUserActivity in localStorage:', err);
  }
}

export async function checkActivity() {
  if (!INACTIVITY_TIME) {
    return;
  }

  try {
    const lastActivity = Number.parseInt(localStorage.getItem('lastUserActivity') || '0', 10);
    const now = Date.now();

    if (now - lastActivity > INACTIVITY_TIME) {
      localStorage.removeItem('lastUserActivity');
      await signOut();
    }
  } catch (err) {
    console.error('Error during activity check:', err);
  }
}
