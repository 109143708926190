'use client';

import { useEffect } from 'react';
import debounce from 'lodash.debounce';

import { activityDetected, checkActivity, setInitialActivity } from '@/lib/auth/browser';

const ACTIVITY_POLLING_INTERVAL = 60_000; // 1 minute for testing, adjust to 15 minutes (900_000 ms) for production
const DEBOUNCE_INTERVAL = 500; // 0.5 seconds

// Create a debounced version of activityDetected
const debouncedActivityDetected = debounce(activityDetected, DEBOUNCE_INTERVAL);

// Enhanced check that accounts for tab focus and visibility
function enhancedCheckActivity() {
  if (document.visibilityState === 'visible') {
    checkActivity();
  }
}

export function useInactivityLogout() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Set initial user activity on component mount
      setInitialActivity();

      // This is to check if they closed the tab or browser
      checkActivity();

      const controller = new AbortController();

      // Add debounced event listeners with abort signal
      window.addEventListener('mousemove', debouncedActivityDetected, { signal: controller.signal });
      window.addEventListener('keydown', debouncedActivityDetected, { signal: controller.signal });
      window.addEventListener('scroll', debouncedActivityDetected, { signal: controller.signal });

      // Listen to visibility changes to handle tab switches or app coming to foreground
      document.addEventListener('visibilitychange', enhancedCheckActivity, { signal: controller.signal });

      const scheduleActivityCheck = () => {
        setTimeout(() => {
          if (!controller.signal.aborted) {
            enhancedCheckActivity();
          }
        }, ACTIVITY_POLLING_INTERVAL);
      };

      scheduleActivityCheck();

      return () => {
        controller.abort();
        debouncedActivityDetected.cancel(); // Cancel any pending debounced calls
      };
    }
  }, []);
}
