import { OperationStatus, SignRequestMetadataSourceType, TransactionMetadataSourceType } from '@/lib/gql/graphql';

export namespace Operation {
  export function hasFailedStatus(status: OperationStatus) {
    return status === OperationStatus.Failed;
  }

  export function hasSigningStatus(status: OperationStatus) {
    return [OperationStatus.Signing, OperationStatus.Presigning, OperationStatus.Broadcasting].includes(status);
  }

  export function getSourceDisplayName(
    source: SignRequestMetadataSourceType | TransactionMetadataSourceType | null | undefined,
  ): string {
    switch (source) {
      case SignRequestMetadataSourceType.Aave:
      case TransactionMetadataSourceType.Aave: {
        return 'Aave';
      }
      case SignRequestMetadataSourceType.Uniswap:
      case TransactionMetadataSourceType.Uniswap: {
        return 'Uniswap';
      }
      case SignRequestMetadataSourceType.WalletConnect:
      case TransactionMetadataSourceType.WalletConnect: {
        return 'Wallet Connect';
      }
      default: {
        return '';
      }
    }
  }

  export function getSourceFromSourceUrl(
    url: string | undefined,
    type: 'sign' | 'transaction',
  ): SignRequestMetadataSourceType | TransactionMetadataSourceType {
    if (!url) {
      return type === 'sign'
        ? SignRequestMetadataSourceType.WalletConnect
        : TransactionMetadataSourceType.WalletConnect;
    }
    if (url.includes('aave')) {
      return type === 'sign' ? SignRequestMetadataSourceType.Aave : TransactionMetadataSourceType.Aave;
    } else if (url.includes('uniswap')) {
      return type === 'sign' ? SignRequestMetadataSourceType.Uniswap : TransactionMetadataSourceType.Uniswap;
    } else {
      return type === 'sign'
        ? SignRequestMetadataSourceType.WalletConnect
        : TransactionMetadataSourceType.WalletConnect;
    }
  }
}
