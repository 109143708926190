import { Amplify } from 'aws-amplify'; // eslint-disable-line no-restricted-imports

import { Env } from '@/lib/env';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      region: Env.cognito.region,
      userPoolId: Env.cognito.poolId,
      userPoolWebClientId: Env.cognito.clientId,
    },
    ssr: true,
  });
}
