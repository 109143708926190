import { FragmentType, getFragmentData } from '@/lib/gql';
import { ComplianceRuleKey, IdentityStatus } from '@/lib/gql/graphql';

import { ComplianceIdentityModelFragment } from './data-fragment';
export namespace Compliance {
  export const DEFAULT_RULE_VALUE = 0;

  export function isRuleAboutCurrency(key: ComplianceRuleKey) {
    return [ComplianceRuleKey.MaxTxSize, ComplianceRuleKey.MaxTxVolume_24H, ComplianceRuleKey.MaxTxVolume_30D].includes(
      key,
    );
  }

  export function orderedRules() {
    return [
      ComplianceRuleKey.MaxTx_24H,
      ComplianceRuleKey.MaxTx_30D,
      ComplianceRuleKey.MaxTxVolume_24H,
      ComplianceRuleKey.MaxTxVolume_30D,
      ComplianceRuleKey.MaxTxSize,
    ];
  }

  export function isIdentityBlocked(identityParam: FragmentType<typeof ComplianceIdentityModelFragment>): boolean {
    const data = getFragmentData(ComplianceIdentityModelFragment, identityParam);
    return [IdentityStatus.Blocked, IdentityStatus.UnblockPending].includes(data.details.status);
  }

  export function isIdentityInPendingStatus(
    identityParam: FragmentType<typeof ComplianceIdentityModelFragment>,
  ): boolean {
    const data = getFragmentData(ComplianceIdentityModelFragment, identityParam);
    return isPendingStatus(data.details.status);
  }

  export function isPendingStatus(status: IdentityStatus) {
    return [IdentityStatus.BlockPending, IdentityStatus.UnblockPending].includes(status);
  }
}
