'use client';

import { useCallback, useState } from 'react';

/**
 * Handles the visibility for an alert or given message.
 * It can toggle alert on and off, and resets the state when the message changes.
 */
export function useFormAlert(message: string | null | undefined) {
  const [previousMessage, setPrevious] = useState<string | undefined>();
  const [showAlert, toggleAlert] = useState(false);

  if (message !== previousMessage) {
    setPrevious(message ?? undefined);
    toggleAlert(Boolean(message));
  }

  const dismiss = useCallback(() => toggleAlert(false), []);

  return { showAlert, dismiss };
}
