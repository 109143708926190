import { graphql } from '@/lib/gql';

export const UserModelFragment = graphql(`
  fragment UserModel on User {
    id
    details {
      fullName
      email
      roles
      type
    }
  }
`);
