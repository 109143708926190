import jwt_decode from 'jwt-decode';
import type { RequestCookies } from 'next/dist/compiled/@edge-runtime/cookies';

import { Env } from '@/lib/env';

import { JwtUserInfo, UserInfo, parseUserInfo } from './user-info';

function getUserIdFromCookies(cookies: Pick<RequestCookies, 'get'>): [Error, null] | [null, string] {
  const userId = cookies.get(`CognitoIdentityServiceProvider.${Env.cognito.clientId}.LastAuthUser`)?.value;
  if (!userId) {
    return [new Error('No authenticated user was found'), null];
  }

  return [null, userId];
}

export function getUserInfoFromCookies(cookies: Pick<RequestCookies, 'get'>): [Error, null] | [null, UserInfo] {
  const [err, userId] = getUserIdFromCookies(cookies);
  if (err) {
    return [err, null];
  }

  const idToken = cookies.get(`CognitoIdentityServiceProvider.${Env.cognito.clientId}.${userId}.idToken`)?.value;
  if (!idToken) {
    return [new Error('No ID token was found'), null];
  }

  return [null, parseUserInfo(jwt_decode(idToken) as JwtUserInfo)];
}

export function getAccessTokenFromCookies(cookies: Pick<RequestCookies, 'get'>): [Error, null] | [null, string] {
  const [err, userId] = getUserIdFromCookies(cookies);
  if (err) {
    return [err, null];
  }

  const accessToken = cookies.get(`CognitoIdentityServiceProvider.${Env.cognito.clientId}.${userId}.accessToken`)
    ?.value;
  if (!accessToken) {
    return [new Error('No access token was found'), null];
  }

  return [null, accessToken];
}
