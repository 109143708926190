import { UserInfo } from './user-info';

/**
 * Verify the permissions (entitlements) a user has
 */
export namespace UserPermissions {
  export function hasChainsPublic(user: UserInfo): boolean {
    return user.permissions['chains-public'].canAccess;
  }

  export function hasChainsPrivate(user: UserInfo): boolean {
    return user.permissions['chains-private'].canAccess;
  }

  export function hasDapps(user: UserInfo): boolean {
    return user.permissions.dapps.canAccess;
  }

  export function hasFlow(user: UserInfo): boolean {
    //see https://github.com/IoFinnet/io-platform-cldsvc-cogn1to/blob/53df8d659272f041b43385fa1b481c7554496963/src/handlers/preTokenGenerationTrigger.ts#L66
    return user.permissions.accounts.canAccess;
  }

  export function hasNetworkCompliance(user: UserInfo): boolean {
    return user.permissions['network-compliance'].canAccess;
  }

  export function hasNetworkPM(user: UserInfo): boolean {
    return user.permissions['network-pm'].canAccess;
  }

  export function hasReports(user: UserInfo): boolean {
    return user.permissions.reports.canAccess;
  }

  export function hasUsers(user: UserInfo): boolean {
    return user.permissions.users.canAccess;
  }

  export function hasVault(user: UserInfo): boolean {
    return user.permissions.vaults.canAccess;
  }
}
/**
 * These are the plans a user can have which are linked to the plans in Chargebee.
 * Plans are an arrangements of entitlements aggregated
 */
export namespace UserPlans {
  export function hasPM(user: UserInfo): boolean {
    return (
      UserPermissions.hasNetworkPM(user) && UserPermissions.hasVault(user) && UserPermissions.hasChainsPrivate(user)
    );
  }

  export function hasUnderlying(user: UserInfo): boolean {
    return UserPermissions.hasChainsPrivate(user) && UserPermissions.hasChainsPrivate(user) && !UserPlans.hasPM(user);
  }

  export function hasVault(user: UserInfo): boolean {
    return UserPermissions.hasVault(user) && UserPermissions.hasChainsPublic(user);
  }

  export function hasFlow(user: UserInfo): boolean {
    return UserPermissions.hasFlow(user);
  }
}
