import { FragmentType } from '@/lib/gql';

import { AddressModelFragment } from './data-fragment';
export * from './data-fragment';

type Address = FragmentType<typeof AddressModelFragment> & { id: string };

export namespace Address {
  // Function to find the IOCHAIN network address
  export function findIOCHAINAddress(addresses: Array<{ id: string }>): Address | undefined {
    return addresses.find(({ id }) => id.startsWith('IOCHAIN'));
  }

  // Function to get the default address or the IOCHAIN network address if applicable
  // If the user has chains-private permissions, default to the IOCHAIN network
  export function getDefaultAddress(
    vault: { addresses: Array<{ id: string }> },
    hasChainsPrivatePermissions: boolean,
  ): Address | undefined {
    const defaultAddress = vault.addresses.at(0);
    if (hasChainsPrivatePermissions) {
      return findIOCHAINAddress(vault.addresses) || defaultAddress;
    }
    return defaultAddress;
  }
}
